import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import moment from 'moment';
import { Link as RouterLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/styles';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Tooltip,
  colors,
  Button,
} from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import StackAvatars from 'src/components/StackAvatars';

const useStyles = makeStyles((theme) => ({
  root: {},
  critical: {
    '& $indicator': {
      borderColor: colors.red[600],
    },
  },
  stable: {
    '& $indicator': {
      borderColor: colors.green[600],
    },
  },
  indicator: {
    height: 12,
    width: 12,
    borderWidth: 4,
    borderStyle: 'solid',
    borderColor: colors.grey[100],
    borderRadius: '50%',
  },
  viewButton: {
    marginLeft: theme.spacing(2),
  },
}));

function MarketItem({ market, className, ...rest }) {
  const classes = useStyles();

  let deadline = 'N/A';
  let critical = false;
  let stable = false;

  if (market.address) {
    const now = moment();
    const deadlineMoment = moment(market.address);

    if (deadlineMoment.isAfter(now) && deadlineMoment.diff(now, 'day') < 3) {
      deadline = `${deadlineMoment.diff(now, 'day')} days remaining`;
      critical = true;
    } else {
      stable = true;
    }
  }

  return (
    <ListItem
      {...rest}
      className={clsx(classes.root, { [classes.stable]: stable }, className)}
    >
      <ListItemIcon>
        <span className={classes.indicator} />
      </ListItemIcon>
      <ListItemText
        className={classes.listItemText}
        primary={market.name}
        primaryTypographyProps={{ variant: 'h6', noWrap: true }}
        secondary={market.address + ', ' + market.city + ', ' + market.state}
      />
      <StackAvatars
        avatars={[
          '/images/avatars/avatar_2.png',
          '/images/avatars/avatar_3.png',
          '/images/avatars/avatar_4.png',
          '/images/avatars/avatar_5.png',
          '/images/avatars/avatar_6.png',
          '/images/avatars/avatar_7.png',
        ]}
        limit={3}
      />
      <Tooltip title="View market">
        <IconButton
          className={classes.viewButton}
          edge="end"
          size="small"
          component={RouterLink}
          to={`/markets/${market.id}/overview`}
        >
          <OpenInNewIcon />
        </IconButton>
      </Tooltip>
    </ListItem>
  );
}

MarketItem.propTypes = {
  className: PropTypes.string,
  market: PropTypes.object.isRequired,
};

export default MarketItem;
