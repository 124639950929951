import axios from '../utils/axios';

export const SESSION_LOGIN = 'SESSION_LOGIN';
export const SESSION_LOGOUT = 'SESSION_LOGOUT';
export const SESSION_LOGIN_FAILED = 'SESSION_LOGIN_FAILED';

// export const login = () => (dispatch) => dispatch({
//   type: SESSION_LOGIN
// });

export const login = (values) => {
  return (dispatch) => {
    console.log(values);

    // console.log(process.env.REACT_APP_API_URL)
    axios
      .post('/api/v1/login/', {
        phone_number: 'email_auth',
        email: values.email,
        password: values.password,
      })
      .then(
        (response) => {
          console.log(
            'response.status fjkhsdgfsdjkhfgsd jkhg s',
            response.status
          );
          if (response.status === 200) {
            dispatch({ type: SESSION_LOGIN, payload: response.data });
          }
          // if (mounted) {
          //   alert(JSON.stringify(response.data))
          // console.log(JSON.stringify(response.status))
          // console.log(JSON.stringify(response.data))
          // setNotifications(response.data.notifications);
          // }
        },
        (error) => {
          console.log(error);
          dispatch({ type: SESSION_LOGIN_FAILED });
        }
      );

    // return fetch("http://localhost:3000/api/v1/users", {
    //   method: "POST",
    //   headers: {
    //     'Content-Type': 'application/json',
    //     Accept: 'application/json',
    //   },
    //   body: JSON.stringify({user})
    // })
    //   .then(resp => resp.json())
    //   .then(data => {
    //     if (data.message) {
    //       // Here you should have logic to handle invalid creation of a user.
    //       // This assumes your Rails API will return a JSON object with a key of
    //       // 'message' if there is an error with creating the user, i.e. invalid username
    //     } else {
    //       localStorage.setItem("token", data.jwt)
    //       dispatch(loginUser(data.user))
    //     }
    //   })
  };
};

export const logout = () => (dispatch) =>
  dispatch({
    type: SESSION_LOGOUT,
  });
