import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { AppBar, Toolbar, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: 'none',
  },
  title: {
    color: theme.palette.common.white,
  },
}));

function Topbar({ className, ...rest }) {
  const classes = useStyles();

  return (
    <AppBar {...rest} className={clsx(classes.root, className)} color="primary">
      <Toolbar>
        <RouterLink to="/">
          <Typography
            className={classes.title}
            // color="textPrimary"
            variant="h2"
          >
            kartMarkets
          </Typography>
          {/*<img*/}
          {/*  alt="Logo"*/}
          {/*  src="/images/logos/logo--white.svg"*/}
          {/*/>*/}
        </RouterLink>
      </Toolbar>
    </AppBar>
  );
}

Topbar.propTypes = {
  className: PropTypes.string,
};

export default Topbar;
