import * as actionTypes from 'src/actions';

const loggedIn = localStorage.getItem('access') != null;
const initialState = {
  loggedIn: loggedIn,
  user: {
    first_name: 'Slava',
    last_name: 'Chabanov',
    email: 'demo@devias.io',
    avatar: '/images/avatars/avatar_11.png',
    bio: 'Software Developer',
    role: 'USER', // ['GUEST', 'USER', 'ADMIN']
  },
  loginFailed: false,
};

const session = (state = initialState, action) => {
  // console.log("INSIDE SessionREDUCES" + JSON.stringify(state));
  switch (action.type) {
    case actionTypes.SESSION_LOGIN: {
      if (action.payload) {
        // console.log("user logged in")
        // console.log(JSON.stringify(action.payload))
        if (action.payload.access) {
          console.log(action.payload.access);
          localStorage.setItem('access', action.payload.access);
          localStorage.setItem('refresh', action.payload.refresh);
        }
        action.payload.user.role = 'USER';
        state = { user: action.payload.user };
        // console.log('!!!!! NEW STATE: ', state )
        return {
          ...state,
          loggedIn: true,
        };
      } else
        return {
          ...initialState,
        };
    }

    case actionTypes.SESSION_LOGOUT: {
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loggedIn: false,
        // user: {
        //     role: 'GUEST'
        // }
      };
    }

    case actionTypes.SESSION_LOGIN_FAILED: {
      console.log(
        'SESSION_LOGIN_FAILED SESSION_LOGIN_FAILED SESSION_LOGIN_FAILED'
      );
      localStorage.removeItem('access');
      localStorage.removeItem('refresh');
      return {
        ...state,
        loggedIn: false,
        loginFailed: true,
        // user: {
        //     role: 'GUEST'
        // }
      };
    }

    default: {
      return state;
    }
  }
};

export default session;
